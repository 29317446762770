<template>
    <div class="backFoodTimeReport defaultReport">
        <div class="content-box clearfix">
            <div class="panel-box top-info-box">
                <el-row>
                    <el-col :xs="12" :sm="8" :lg="6">
                        <span class="lable-text">营业日期：{{data?.Rpt_Date}} </span>
                    </el-col>
                    <el-col :xs="12" :sm="8" :lg="6">
                        <span class="lable-text">结账单号:{{data?.Eat_CheckIndex}}</span>
                    </el-col>
                    <el-col :xs="12" :sm="8" :lg="6">
                        <span class="lable-text">桌号：{{data?.Eat_DeskName}}</span>
                    </el-col>
                    <el-col :xs="12" :sm="8" :lg="6">
                        <span class="lable-text">分析菜品：{{data?.Eat_XFName}}</span>
                    </el-col>
                </el-row>
                <div class="right-bnts">
                    <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
                </div>
            </div>
            <div class="table-box grey-table headerCol2" ref="tableBox">
                <el-table class="el-table--scrollable-y"  :data="tableListPage" 
                 border 
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                    <el-table-column fixed prop="Eat_XFCode" label="菜品编码" min-width="80"></el-table-column>
                    <el-table-column fixed prop="Eat_XFName" label="菜品名称" min-width="80"></el-table-column>
                    <el-table-column prop="Eat_Number" label="下单数量" min-width="60"></el-table-column>
                    <el-table-column prop="Eat_XFSize" label="单位" min-width="70"></el-table-column>
                    <el-table-column label="系统标准值(单位:分)">
                        <el-table-column prop="KIT_LENGTH" label="标准时长" min-width="80" >
                            <template #header>
                                <el-tooltip  effect="dark" content="来源菜品设置项【制作标准时长】" placement="top">
                                    <div class="tip">标准时长
                                        <svg class="icon-svg" aria-hidden="true">
                                            <use xlink:href="#icon-infoFilled"></use>
                                        </svg>
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="EndCookTime" label="理论完成时间" min-width="100" >
                            <template #header>
                                <el-tooltip  effect="dark" content="按制作标准时长和菜品等级运算值" placement="top">
                                    <div class="tip">理论完成时间
                                        <svg class="icon-svg" aria-hidden="true">
                                            <use xlink:href="#icon-infoFilled"></use>
                                        </svg>
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="出菜(单位:分)">
                        <el-table-column prop="RealCookTime" label="出菜时长" min-width="80" >
                            <template #header>
                                <el-tooltip  effect="dark" content="划菜时间-起菜时间" placement="top">
                                    <div class="tip">出菜时长
                                        <svg class="icon-svg" aria-hidden="true">
                                            <use xlink:href="#icon-infoFilled"></use>
                                        </svg>
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="OverTime" label="超时时长" min-width="80" >
                            <template #header>
                                <el-tooltip  effect="dark" content="划菜时间-理论完成时间" placement="top">
                                    <div class="tip">超时时长
                                        <svg class="icon-svg" aria-hidden="true">
                                            <use xlink:href="#icon-infoFilled"></use>
                                        </svg>
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="TransferFoodTime" label="传菜时长(单位:分)" min-width="80">
                        <template #header>
                            <el-tooltip  effect="dark" content="上桌时间-划菜时间" placement="top">
                                <div class="tip">传菜时长
                                    <svg class="icon-svg" aria-hidden="true">
                                        <use xlink:href="#icon-infoFilled"></use>
                                    </svg>
                                    (单位:分)
                                </div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="前厅操作">
                        <el-table-column prop="Eat_Time" label="点菜" min-width="70" >
                            <template #default="scope">
                                {{scope.row.Eat_Time}}
                                <p class="small">{{scope.row.Eat_Czy}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="" label="起菜" min-width="70" >
                            <template #default="scope">
                                {{scope.row.CallUp_Time}}
                                <p class="small">{{scope.row.CallUp_Czy}}</p>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="后厨操作">
                        <el-table-column prop="Cut_Time" label="切配" min-width="70" >
                            <template #default="scope">
                                {{scope.row.Cut_Time}}
                                <p class="small">{{scope.row.Cut_Czy}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Do_Time" label="制作" min-width="70" >
                            <template #default="scope">
                                {{scope.row.Do_Time}}
                                <p class="small">{{scope.row.Do_Czy}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="End_Time" label="划菜" min-width="70" >
                            <template #default="scope">
                                {{scope.row.End_Time}}
                                <p class="small">{{scope.row.End_Czy}}</p>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="OnDesk_Time" label="上桌" min-width="70" >
                        <template #default="scope">
                            {{scope.row.OnDesk_Time}}
                            <p class="small">{{scope.row.OnDesk_Czy}}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="退菜信息">
                        <el-table-column prop="Refund_Number" label="退菜数量" min-width="70" ></el-table-column>
                        <el-table-column prop="Refund_Czy" label="退菜人" min-width="70" ></el-table-column>
                        <el-table-column prop="Refund_Time" label="时间" min-width="70" ></el-table-column>
                        <el-table-column prop="Refund_Reason" label="退菜原因" min-width="150" ></el-table-column>
                    </el-table-column>
                </el-table>
            </div>
            <table-page-btn class="fy" :data="tableList" 
                :default-index="currentPage" 
                @current-change="(index)=>currentPage=index"
                @page-change="(list)=>tableListPage=list" 
                :page-size="pagesize"></table-page-btn>
        </div>
        
     </div>
</template>
<script>

import FileSaver from 'file-saver'
import XLSX from 'xlsx'

/**退菜时间分析表A3 */
export default {
    name:'backFoodTimeReport',
    props:{
        data:{
            type:Object,
            default:null
        }
    },
    data(){
        return {
            userInfo:{},
            /**表格数据 */ 
            currentPage:1,
            tableList:[],
            tableListPage:[],
            pagesize:9,//每页的数据条数
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
    },
    watch:{
        data:{
            handler(){
                if(this.data){
                   this.$nextTick(()=>{
                        this.GetBillCheck();
                   })
                }
            },
            immediate:true,
        }
    },
    methods:{
        /**计算 一页显示多少条数据 */
        setTablePageSize(){
            if(this.$refs.tableBox){
                this.$nextTick(()=>{
                    let height=this.$refs.tableBox.clientHeight - 80;
                    this.pagesize=parseInt(height/50);
                })
            }
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            let _pagesize = this.pagesize
            this.pagesize = this.tableList.length;//表格长度变长
            this.currentPage= 1;
            this.$refs.tableBox.style.display="none";
            this.$nextTick(function () {
                let wb = XLSX.utils.book_new();
                let ws=this.$addSheetCell([
                    [
                        {value:'营业日期：'+this.data?.Rpt_Date,col:3}, 
                        {value:'结账单号：'+this.data?.Eat_CheckIndex,col:3}, 
                        {value:'桌号：'+this.data?.Eat_DeskName,col:3}, 
                        {value:'分析菜品：'+this.data?.Eat_XFName,col:3}, 
                    ],
                ]);
            
                let table = this.$refs.tableBox.cloneNode(true);
                table.querySelector(".el-table__fixed")?.remove();
                table.querySelectorAll(".el-table__footer td")?.forEach(it=>{
                    it.setAttribute("rowspan","1");
                })
                let xlsxParam = { raw: true,origin:"A2" ,display:true};
                let sheet = XLSX.utils.sheet_add_dom(ws,table,xlsxParam);

                XLSX.utils.book_append_sheet(wb,sheet,'退菜时间分析表');
                let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'});
                try {
                    FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), '退菜时间分析表.xlsx')
                } catch (e) {
                    if (typeof console !== 'undefined') console.log(e, wbout)
                }
                this.pagesize = _pagesize;//表格还原
                this.$nextTick(()=>{
                    this.$refs.tableBox.style.display='';
                })
                return wbout
            })
        },
        /**今日 */
        nowadays(){
            this.queryType=false
            this.businessHours=[this.userInfo.Rpt_Date,this.userInfo.Rpt_Date]
        },
        change(){
            if(this.queryType==false){
                this.queryType=true
            }
        },
         /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /**查询 */
        GetBillCheck(){
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Rpt_Date:this.data?.Rpt_Date,
                Eat_AutoID:this.data?.Eat_AutoID,//订单ID
                Eat_XFBMID:this.data?.Eat_XFBMID,//菜品ID
            }
            console.log("退菜 param:",param)
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            this.$httpAES.post("Bestech.CloudPos.FoodRefundTimeAnalysisReport",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList=d.ResponseBody
                    this.setTablePageSize()
                    this.currentPage=1
                }else{
                    this.tableList=[];
                    this.currentPage=1
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableList=[];
                this.currentPage=1
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        }
    }
}
</script>

<style lang="scss">
@import './backFoodTimeReport.scss'
</style>